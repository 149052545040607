(<template>
  <article :class="{'page-data-skeleton--expanded': expanded}"
           class="page-data-skeleton">
    <div :class="{'mob-nav-active': showMobNav}"
         class="page-data-skeleton__nav-wrapper">
      <slot></slot>
    </div>
    <div class="page-data-skeleton__view-wrapper">
      <router-view />
    </div>
  </article>
</template>)

<script>
  export default {
    props: {
      showMobNavigation: Boolean,
      expanded: Boolean
    },
    computed: {
      showMobNav() { return this.showMobNavigation && this.clientWidth < 767; },
      clientWidth() { return this.$store.state.GlobalStore.clientWidth; }
    }
  };
</script>

<style scoped src="@assets/css/page-data-skeleton.css"></style>

<style src="@assets/css/page-data-section.css"></style>
