// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/arrows/big_arrow_to_right.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".page-data-sidebar[data-v-47d8e33c] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-negative: 0;\n      flex-shrink: 0;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  overflow: hidden;\n  overflow-y: auto;\n  width: 100%;\n  height: 100%;\n  padding: 0 60px 0 20px;\n}\n.page-data-sidebar__link[data-v-47d8e33c]:first-child {\n  border-top: 1px solid #d3d5de;\n}\n.page-data-sidebar__link[data-v-47d8e33c] {\n  width: auto;\n  padding: 12px;\n  border-bottom: 1px solid #d3d5de;\n  font-size: 12px;\n  text-align: left;\n}\n.page-data-sidebar__link[data-v-47d8e33c]:focus {\n  margin: 4px 0;\n  outline: var(--default-button-focus-outline);\n}\n.page-data-sidebar__link.router-link-active[data-v-47d8e33c] {\n  background: #fff;\n  font-weight: bold;\n}\n.page-data-sidebar__mob-title[data-v-47d8e33c] {\n  display: none;\n}\n@media (max-width: 767px) {\n.page-data-sidebar[data-v-47d8e33c] {\n    padding: 15px;\n    padding-bottom: 60px;\n    background: #fff;\n}\n.page-data-sidebar__mob-title[data-v-47d8e33c] {\n    display: block;\n    margin-bottom: 15px;\n    font-weight: bold;\n    font-size: 18px;\n}\n.page-data-sidebar__link[data-v-47d8e33c] {\n    position: relative;\n    padding: 20px 0;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-position: 100% 50%;\n    background-size: 12px auto;\n    background-repeat: no-repeat;\n    font-size: 16px;\n}\n}\n", ""]);
// Exports
module.exports = exports;
