(<template>
  <nav class="page-data-sidebar">
    <p v-if="mobTitle"
       class="page-data-sidebar__mob-title"
       v-html="mobTitle"></p>
    <router-link v-for="link in filteredNavLinksList"
                 :key="link.title"
                 :to="$makeRoute(link.route)"
                 class="page-data-sidebar__link"
                 v-html="link.title" />
  </nav>
</template>)

<script>
  import {mapGetters} from 'vuex';

  export default {
    computed: {
      ...mapGetters('UserInfoStore', ['userIsInterpreter', 'userIsNotInterpreter']),
      mobTitle() { return ''; },
      navLinksList() { return []; },
      filteredNavLinksList() {
        return this.navLinksList.filter((link) => {
          return link.condition !== undefined ? link.condition : link.route;
        });
      }
    }
  };
</script>

<style scoped src="@assets/css/page_data_sidebar.css"></style>
