<script>
  import PageDataSidebar from '@/components/shared_components/page_data/PageDataSidebar';

  export default {
    extends: PageDataSidebar,
    computed: {
      mobTitle() { return this.$gettext('Settings'); },
      navLinksList() {
        return [{
          title: this.$gettext('Availability'),
          route: this.userIsInterpreter ? {name: 'ServerPreferencesAvailability'} : ''
        }, {
          title: this.$gettext('Notifications'),
          route: this.userIsNotInterpreter ? {name: 'BuyerPreferencesNotifications'} : ''
        }, {
          title: this.$gettext('Interpretation Notifications'),
          route: this.userIsInterpreter ? {name: 'ServerInterpretationNotifications'} : ''
        }, {
          title: this.$gettext('Translation Notifications'),
          route: this.userIsInterpreter ? {name: 'ServerTranslationNotifications'} : ''
        }];
      }
    }
  };
</script>
