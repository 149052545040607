(<template>
  <setting-page-skeleton :show-mob-navigation="showMobNav">
    <sidebar-nav />
  </setting-page-skeleton>
</template>)

<script>
  import SidebarNav from '@/components/shared_components/page_data/PageDataSidebar';
  import PageDataSkeleton from '@/components/shared_components/page_data/PageDataSkeleton';

  export default {
    components: {
      'sidebar-nav': SidebarNav,
      'setting-page-skeleton': PageDataSkeleton
    },
    computed: {
      showMobNav() { return true; }
    }
  };
</script>
