<script>
  import PreferenceNav from '@/components/preferences/PreferencesNav';
  import PageDataRoot from '@/components/shared_components/page_data/PageDataRoot';

  export default {
    components: {
      'sidebar-nav': PreferenceNav
    },
    extends: PageDataRoot,
    computed: {
      showMobNav() {
        return this.$route.name == 'ServerPreferences';
      }
    }
  };
</script>
